/* eslint-disable global-require */

module.exports = {
	header: require("./header.json"),
	footer: require("./footer.json"),
	graphql: require("./graphql.json"),
	pricing: require("./pricing.json"),
	"prism-anova": require("./prism-anova.json"),
	"power-analysis": require("./power-analysis.json"),
	prism: require("./prism.json"),
	"prism-nonlinear-regression": require("./prism-nonlinear-regression.json"),
	invoice: require("./invoice.json"),
	company: require("./company.json"),
	customers: require("./customers.json"),
	cloud: require("./cloud.json"),
	"pay-invoice-online": require("./pay-invoice-online.json"),
	"section-try-for-free": require("./section-try-for-free.json"),
	"section-involve-your-team": require("./section-involve-your-team.json"),
	activations: require("./activations.json"),
	entitlements: require("./entitlements.json"),
	guides: require("./guides.json"),
	preferences: require("./preferences.json"),
	"app-error": require("./app-error.json"),
	"survival-analysis": require("./survival-analysis.json"),
	"section-start-free-30-day-trial": require("./section-start-free-30-day-trial.json"),
	"available-on-os": require("./available-on-os.json"),
	"free-course-licenses": require("./free-course-licenses"),
	"prism-academy": require("./prism-academy.json"),
	"series-page": require("./series-page.json"),
	"create-connected-lab-form": require("./create-connected-lab-form.json"),
	contact: require("./contact.json"),
	enterprise: require("./enterprise.json"),
	"machine-learning": require("./machine-learning.json"),
	terms: require("./terms.json"),
	"product-terms": require("./product-terms.json"),
};
